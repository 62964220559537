@mixin v-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.v-align {
  @include v-align();
}
.fa-file-pdf-o {
  &.art,&.doc {
    font-size: $font-size + 4px;
  }
}
html, body {
  overflow-x: inherit !important;
}
body {
  font: normal 62.5%/1em $font-family;
  font-family: $font-family, sans-serif;
  color: $main-black-color;
  font-size: $font-size;
  line-height: 1.7rem;
}
.header-full {
  background-color: $main-green-color;
  background-image: none;
  height: 100px;
  &-title {
    float: none;
    padding-left: 0px;
    h1 {
      font-family: $font-family;
      line-height: 100px;
      height: 100px;
      margin: 0;
      display: inline-block;
      word-spacing: 0px;
      img {
        height: 45px;
      }
      span {
        padding-left: 30px;
        a {
          i {
            padding-bottom: 1px;
            border-bottom: 2px solid #fff;
            margin-right: 10px;
            font-size: $font-size + 2px;
          }
          font-weight: normal;
          font-size: $font-size;
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
  &-search {
    line-height: 100px;
    h4 {
      cursor: pointer;
      background-color: #fff;
      display: inline-block;
      width: 100%;
      padding: 10px 5px 10px 10px;
      margin: 0;
      color: $main-green-color;
      font-weight: 400;
      font-size: $font-size + 2px;
      i {
        display: inline-block;
        font-size: $font-size + 8px;
        line-height: 18px;
        margin: 0 5px;
        font-weight: 400;
        color: $main-green-color;
      }
    }
  }
}

@media (min-width: 767px) {
  .navbar-default {
    .navbar-nav{
      & > li {
        &:first-child {
          & > a {
            &:focus,&:hover {
              background-color: transparent !important;
              color: #fff !important;
            }
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 0px;
            i {
              line-height: 40px;
              height: 40px;
              font-size: $font-size + 8px;
            }
          }
        }
        & > a {
          &:focus,&:hover {
            background-color: $main-blue-color !important;
            background-image: none;
            color: $second-blue-color !important;
          }
          text-transform: uppercase;
          word-spacing: -1px;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-top: 0px;
          color: #000;
          font-family: $font-family;
          font-size: $font-size;
          font-weight: 400;
        }
      }
    }
  }
}
.navbar-header-full{
  &.navbar-dark {
    border-top: none;
  }
}
.navbar {
  position: relative;
  min-height: 40px;
}
.navbar-default.navbar-dark .navbar-nav > .open > a,
.navbar-default.navbar-dark .navbar-nav > .open > a:hover,
.navbar-default.navbar-dark .navbar-nav > .open > a:focus {
  background-color: $main-blue-color;
  background-image: none;
  color: $second-blue-color;
}
.navbar-dark {
  background-color: $main-blue-color;
  color: $second-blue-color;
  box-shadow: none;
}

.navbar-dark .dropdown .dropdown-menu {
  background-color: $main-blue-color;
  color: #fff;
  border-color: $main-blue-color;
  box-shadow: none;
}
.dropdown>a:after {
  top: 18px;
  right: 15px;
  content: "";
}
.navbar-dark .dropdown .dropdown-menu a {
  font-size: $font-size;
}
@media (min-width: 767px) {
  .dropdown-menu li a {
    font-size: $font-size;
    font-weight: 400;
    padding: 7px 15px;
  }
}
@media (min-width: 767px) {
.navbar-default .navbar-nav>li.dropdown>a {
  padding-right: 15px;
}
}
.navbar-dark .dropdown .dropdown-menu a:hover,
.navbar-dark .dropdown .dropdown-menu a:focus {
  color: $second-blue-color;
}
.navbar-dark .dropdown.active > a,
.navbar-dark .dropdown a:hover,
.navbar-dark .dropdown a:focus,
.navbar-dark .dropdown.active a:hover,
.navbar-dark .dropdown.active a:focus {
  background-color: $main-blue-color !important;
}

.modal-backdrop {
  background-color: #fff;
  &.in {
    opacity: 0.5;
  }
}

.modal {
  .toolbar {
    .modal-content {
      border-right: none;
      border-left: none;
      border-top: none;
    }
    .modal-header {
      padding: 0 15px;
      height: 50px;
      background-color: $main-green-color;
      color: #fff;
      button {
        &.close {
          &:hover,&:focus,&:active {
            color: #fff;
          }
          word-spacing: -1px;
          margin-top: 0px;
          float: right;
          font-size: $font-size + 14px;
          font-weight: 500;
          line-height: 50px;
          color: #fff;
          text-shadow: 0 1px 0 #fff;
          opacity: 1;
        }
      }
      .modal-title {
        line-height: 50px;
        color: #fff;
        text-transform: uppercase;
        font-size: $font-size;
        font-weight: 500;
      }
    }
    width: 100%;
    margin: 0 auto;
    &-bottom {
      position: absolute;
      bottom: 0;
    }
  }
}
.toolbarCartBox {
  .scroll-bar {
    overflow: auto;
    max-height: 400px;
  }
  height: 500px;
  .table {
    .counter {
      width: 10%;
      select {
        width: 100px;
        padding: 5px;
      }
    }
    .action {
      width: 10%;
      i {
        color: $color-pink;
        font-size: $font-size + 8px;
      }
    }
  }
  .nav-tabs>li {
    max-width: 375px;
    float: left;
    margin-bottom: -1px;
  }
  .nav-tabs.nav-tabs-ar li.active a,
  .nav-tabs.nav-tabs-ar li.active a:focus,
  .nav-tabs.nav-tabs-ar li.active a:hover {
    border-top: none;
    background-color: $main-green-color;
    border-left: none;
    border-right: none;
    color: #fff;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 600;
  }
  .nav-tabs.nav-tabs-ar li:not(.active) a:hover {
    border-top: none;
    background-color: #fff;
    border-left: none;
    border-right: none;
    color: $main-green-color;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 600;
  }
  .nav-tabs.nav-tabs-ar li a {
    text-align: center;
    text-transform: uppercase;
    font-family: $font-family;
    font-weight: 600;
    transition: none;
    border: none !important;
    color: $main-green-color;
    background-color: #fff;
    padding-right: 15px;
    padding-left: 15px;
  }
  .nav-tabs.nav-tabs-ar+.tab-content {
    padding: 15px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-top: 1px solid $main-green-color;
    overflow: hidden;
    background-color: #fff;
  }
  .nav-tabs.nav-tabs-ar {
    border-top: none;
    border-right: none;
    font-family: $font-family,sans-serif;
    background-color: #fff;
  }
}
.toolbarFormBox {
  .nav-tabs>li {
    max-width: 175px;
    float: left;
    margin-bottom: -1px;
  }
  .nav-tabs.nav-tabs-ar li.active a,
  .nav-tabs.nav-tabs-ar li.active a:focus,
  .nav-tabs.nav-tabs-ar li.active a:hover {
    border-top: none;
    background-color: $main-green-color;
    border-left: none;
    border-right: none;
    color: #fff;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 600;
  }
  .nav-tabs.nav-tabs-ar li:not(.active) a:hover {
    border-top: none;
    background-color: #fff;
    border-left: none;
    border-right: none;
    color: $main-green-color;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 600;
  }
  .nav-tabs.nav-tabs-ar li a {
    text-align: center;
    text-transform: uppercase;
    font-family: $font-family;
    font-weight: 600;
    transition: none;
    border: none !important;
    color: $main-green-color;
    background-color: #fff;
    padding-right: 15px;
    padding-left: 15px;
  }
  .nav-tabs.nav-tabs-ar+.tab-content {
    padding: 15px;
    border-left: 1px solid $main-green-color;
    border-right: 1px solid $main-green-color;
    border-bottom: 1px solid $main-green-color;
    overflow: hidden;
    background-color: $main-green-color;
  }
  .nav-tabs.nav-tabs-ar {
    border-top: none;
    border-right: none;
    font-family: $font-family,sans-serif;
    background-color: #fff;
  }
}
.toolbarSearchBox {
  .nav-tabs>li {
    max-width: 175px;
    float: left;
    margin-bottom: -1px;
  }
  .nav-tabs.nav-tabs-ar li.active a,
  .nav-tabs.nav-tabs-ar li.active a:focus,
  .nav-tabs.nav-tabs-ar li.active a:hover {
    border-top: none;
    background-color: $main-green-color;
    border-left: none;
    border-right: none;
    color: #fff;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 600;
  }
  .nav-tabs.nav-tabs-ar li:not(.active) a:hover {
    border-top: none;
    background-color: #fff;
    border-left: none;
    border-right: none;
    color: $main-green-color;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 600;
  }
  .nav-tabs.nav-tabs-ar li a {
    text-align: center;
    text-transform: uppercase;
    font-family: $font-family;
    font-weight: 600;
    transition: none;
    border: none !important;
    color: $main-green-color;
    background-color: #fff;
    padding-right: 15px;
    padding-left: 15px;
  }
  .nav-tabs.nav-tabs-ar+.tab-content {
    padding: 15px;
    border-left: 1px solid $main-green-color;
    border-right: 1px solid $main-green-color;
    border-bottom: 1px solid $main-green-color;
    overflow: hidden;
    background-color: $main-green-color;
  }
  .nav-tabs.nav-tabs-ar {
    border-top: none;
    border-right: none;
    font-family: $font-family,sans-serif;
    background-color: #fff;
  }
}
.form-group {
  margin: 10px 0px;
  ::placeholder {
    color: $second-gray-color;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: $second-gray-color;
  }
  ::-ms-input-placeholder {
    color: $second-gray-color;
  }
}
.form-control, .page-content .form-control{
  display: block;
  border-radius: 0;
  width: 100%;
  height: 40px !important;
  padding: 2px 10px;
  font-size: $font-size + 2px;
  font-weight: 400;
  word-spacing: -1px;
  line-height: 1.2;
  color: $main-black-color;
  background-color: #fff;
  background-image: none;
  border: 1px solid $second-gray-color;
  border-radius: 0px;
  box-shadow: none;
  transition: none;
  outline: none;
  &:focus {
    border-color: $main-green-color;
    box-shadow: none;
    & + .input-group-addon {
      border-color: $main-green-color;
    }
  }
}

.input-group {
  .form-control {
    border-right: none;
  }
}
.input-group-addon {
  border-radius: 0;
  background-color: transparent;
  border-color: $second-gray-color;
  border-left: none;
  color: $main-green-color;
  i {
    &.fa {
      font-size: $font-size + 10px;
    }
  }
}
.form-group .glyphicon {
  margin-top: 0px;
  font-size: $font-size + 4px;
}
.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  pointer-events: none;
}

.section-title {
  border-bottom: none;
  padding: 15px 0;
  margin-top: 20px;
  color: $main-blue-color;
  text-transform: uppercase;
  font-weight: 600;
  font-size: $font-size + 14px;
  &:first-letter {
    color: $main-blue-color;
  }
}
a {
  color: $main-blue-color;
}

a:hover,a:active,a:focus {
  color: $main-blue-color;
  text-decoration: none;
}
h1, h2, h3, h4, h5, h6 {
  font-family: $font-family, sans-serif;
  font-weight: 300;
  line-height: 120%;
  color: $main-blue-color;
  margin: 0px;
  word-spacing: -1px;
}
.public-data {
  font-size: $font-size - 2px;
  font-weight: bold;
}
.news-list {
  &-filters {
    margin-bottom: 20px;
    .news-list-filter {
      margin-left: -1px;
      display: inline-block;
      margin-right: 30px;
      & > li {
        &.active:first-child {
          a {
            border-bottom: 1px dotted transparent;
          }
        }
        &.active {
          &.dropdown.open {
            border-top: 1px solid $second-gray-color;
            border-left: 1px solid $second-gray-color;
            border-right: 1px solid $second-gray-color;
            .dropdown-menu {
              min-width: none !important;
              background-color: #fff;
              border-top: none !important;
              border-left: 1px solid $second-gray-color !important;
              border-right: 1px solid $second-gray-color !important;
              border-bottom: 1px solid $second-gray-color !important;
              li {
                display: block;
                padding: 5px 10px;
              }
            }
          }
          a {
            border-bottom: 1px dotted transparent;
            color: $second-gray-color;
            &:hover {
              color: $main-blue-color;
            }
          }
        }
        padding: 10px 20px 10px 5px;
        & > a {
          border-bottom: 1px dotted $main-blue-color;
          cursor: pointer;
          font-size: $font-size;
          font-weight: 500;
          font-family: $font-family;
          word-spacing: -1px;
        }
      }
    }
  }
  &-item {
    .public-data {
      font-size: $font-size - 2px;
    }
    display: block;
    margin-bottom: 20px;
    a {
      color: $main-black-color;
    }
    h4 {
      font-weight: 600;
      a {
        color: $main-blue-color !important;
      }
    }
    .img-caption-ar {
      position: relative;
      padding: 0px 0px 0 0px;
      margin-left: 0px;
      border: 1px solid transparent;
      margin-bottom: 20px;
      color: #fff;
      width: 100%;
      text-align: center;
      img {
        display: block;
      }
    }
  }
}
.img-caption-ar {
  box-shadow: none !important;
}
.art-list {
  &-filters {
    margin-bottom: 20px;
    .art-list-filter {
      margin-left: -1px;
      display: inline-block;
      margin-right: 30px;
      & > li {
        &.active:first-child {
          a {
            border-bottom: 1px dotted transparent;
          }
        }
        &.active {
          &.dropdown.open {
            border-top: 1px solid $second-gray-color;
            border-left: 1px solid $second-gray-color;
            border-right: 1px solid $second-gray-color;
            .dropdown-menu {
              min-width: none !important;
              background-color: #fff;
              border-top: none !important;
              border-left: 1px solid $second-gray-color !important;
              border-right: 1px solid $second-gray-color !important;
              border-bottom: 1px solid $second-gray-color !important;
              li {
                display: block;
                padding: 5px 10px;
              }
            }
          }
          a {
            border-bottom: 1px dotted transparent;
            color: $second-gray-color;
            &:hover {
              color: $main-blue-color;
            }
          }
        }
        padding: 10px 20px 10px 5px;
        & > a {
          border-bottom: 1px dotted $main-blue-color;
          cursor: pointer;
          font-size: $font-size;
          font-weight: 500;
          font-family: $font-family;
          word-spacing: -1px;
        }
      }
    }
  }
  & > .row > div[class^=col] {
    &:nth-child(2n+1) {
      clear: left;
    }
  }
  &-item {
    .img-caption-ar {
      margin-bottom: 0px;
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }
    .public-data {
      font-size: $font-size - 2px;
    }
    &-doc {
      &,&:hover {
        color: $second-gray-color;
      }
      span {
        display: inline-block;
        margin-left: 10px;
        font-size: $font-size - 2px;
        line-height: $font-size + 10px;
      }
      i {
        float: left;
        font-size: $font-size + 10px;
        color: $color-pink;
      }
    }
    display: block;
    margin-bottom: 20px;
    h4 {
      font-weight: 600;
      font-size: $font-size + 2px;
      a {
        color: $main-blue-color !important;
      }
    }
    .img-caption-ar {
      position: relative;
      padding: 0px 0px 0 0px;
      margin-left: 0px;
      border: 1px solid transparent;
      margin-bottom: 20px;
      color: #fff;
      width: 100%;
      text-align: center;
      img {
        display: block;
      }
    }
  }
}

.tag {
  &-label {
    padding: 2px 5px;
    border-radius: 40px;
    border: 1px solid;
    font-size: $font-size - 2px;
    &.blue {
      border-color: $main-blue-color;
      color: $main-blue-color;
      &.bg {
        color: #fff;
        background-color: $main-blue-color;
      }
    }
    &.green {
      border-color: $main-green-color;
      color: $main-green-color;
    }
  }
}

.vendors-list {
  &.view {
    .vendors-list-item {
      .img-caption-ar {
        margin-bottom: 5px;
      }
      h5 {
        padding: 0px 0px;
        a {
          font-size: $font-size;
          color: $main-black-color;
        }
      }
    }
  }
  &-item {
    &:nth-child(6n+1) {
      clear: left;
    }
    display: none;
    &.animated {
      display: block;
    }
    margin-bottom: 20px;
    .img-caption-ar {
      position: relative;
      padding: 0px 0px 0 5px;
      margin-left: 0px;
      border: 1px solid transparent;
      margin-bottom: 0px !important;
      color: #fff;
      width: 100%;
      text-align: center;
      img {
        display: inline;
      }
    }
  }
  &-views {
    margin-top: -15px;
    .vendors-list-view {
      & > li {
        &:first-child {
          margin-right: 15px;
        }
        &.active {
          a {
            i {
              color: $second-gray-color;
            }
            color: $second-gray-color;
            &:hover {
              i {
                color: $main-blue-color;
              }
              color: $main-blue-color;
            }
          }
        }
        padding: 0 1px;
        & > a {
          i {
            font-size: $font-size + 5px;
            margin-right: 5px;
            padding-top: 6px;
            float: left;
          }
          display: inline-block;
          line-height: 33px;
          cursor: pointer;
          font-size: $font-size;
          font-weight: 500;
          font-family: $font-family;
          word-spacing: -1px;
        }
      }
    }
  }
  &-filters {
    margin-bottom: 20px;
    .vendors-list-filter {
      margin-left: -1px;
      display: inline-block;
      margin-right: 30px;
      & > li {
        &.active {
          a {
            color: $second-gray-color;
            &:hover {
              color: $main-blue-color;
            }
          }
        }
        padding: 0 1px;
        & > a {
          cursor: pointer;
          font-size: $font-size;
          font-weight: 500;
          font-family: $font-family;
          word-spacing: -1px;
        }
      }
    }
  }
  h4 {
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 600;
  }
}
.modal {
  padding: 0 !important;
}
.main {
  h3 {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }
  &.vendors-list-main {
    .img-caption-ar {
      border: none;
    }
  }
}

hr.main {
  display: block;
  border-top: 5px solid #d0dde2;
  margin: 30px 0 40px 0px;
}
.breadcrumb {
  padding: 8px 0;
  margin-top: 10px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #fff;
  border-radius: 0px;
  li {
    font-size: $font-size - 2px;
    color: #99999b;
  }
  a {
    color: #2f687f;
    font-size: $font-size - 2px;
    font-weight: 500;
  }
}
.breadcrumb>li+li:before {
  padding: 0 8px 0 3px;
  content: "/";
  font-size: $font-size - 2px;
}
.footer {
  #footer-widgets {
    background-color: $main-blue-color;
    color: #fff;
    padding: 20px 0;
    margin-top: 30px;
    border-top: none;
    box-shadow: none;
    .logo {
      margin-right: 20px;
      img {
        max-height: 30px;
      }
    }
    .copyright {
      line-height: 30px;
      p {
        font-size: $font-size - 2px;
        line-height: 30px;
      }
    }
    .footer-menu {
      & > li {
        &:first-child {
          a {
            i {
              font-size: $font-size + 6px;
            }
          }
        }
        & > a {
          text-transform: uppercase;
          word-spacing: -1;
          font-family: $font-family;
          color: #fff;
          &:hover {
            color: #fff;
          }
          line-height: 22px;
        }
      }
    }
  }
  #footer {
    padding: 20px 0;
    background-color: #fff;
    text-align: left;
    .social-links {
      a {
        display: block;
        width: 35px;
        height: 35px;
        text-align: center;
        margin: 0 3px;
      }
      i {
        display: block;
        width: 35px;
        height: 35px;
        font-size: $font-size + 6px;
        color: #fff;
        line-height: 35px;
        &.fa-youtube {
          background-color: #d0021b;
        }
        &.fa-rss {
          background-color: #f5a623;
        }
      }
    }
    p {
      color: #b6b6b7;
      font-size: $font-size - 2px;
      font-family: $font-family;
    }
  }
}

.wrap-hero {
  min-height: 150px;
  margin-bottom: 10px;
  .carousel-hero {
    background-color: transparent;
    padding:0;
    position: relative;
    height: 100%;
    .item {
      padding: 20px 0;
      &-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
      }
      & > .row {
        display: flex;
      }
      &-img {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        width: 100%;
        height: 150px;
      }
      &-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .carousel-caption {
      font-family: $font-family;
      padding: 0;
      margin: 0;
      color: $main-black-color;
      font-size: $font-size;
      text-shadow: none;
      margin-top: 10px;
      & > a {
        color: $main-black-color;
        font-weight: normal;
        text-decoration: none;
        display: block;
        margin: 0;
        border-bottom: none;
      }
      & > * {
        line-height: inherit;
      }
    }
  }
  .carousel-indicators {
    margin-bottom: -45px;
    .active {
      background-color: $main-blue-color;
    }
    li {
      border: 2px solid $main-blue-color;
    }
  }
}
.text-center {
  text-align: center;
}
.all-pages {
  font-weight: 500;
  word-spacing: -1px;
  margin: 20px 0 10px 0px;
  border-bottom: 1px solid $main-blue-color;
}
.sites-list {
  &-item {
    &:nth-child(4n+1) {
      clear: left;
    }
    padding: 10px;
    .img-caption-ar {
      width: 60%;
      margin-left: 20%;
      padding: 0px;
      margin-bottom: 25px;
      border: 1px solid rgba(255,255,255,.8);
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }
    h5 {
      padding: 5px 0 !important;
      margin-bottom: 5px;
      i {
        position: absolute;
        display: inline-block;
        font-size: 15px;
        font-weight: 600;
        margin: -2px 0 0 10px;
      }
    }
    h5,p {
      font-size: $font-size;
      word-spacing: -1px;
    }
    p {
      font-weight: 500;
    }
    h5 {
      font-weight: 600;
    }
  }
}

.office-list {
  &-item {
    h5 {
      font-size: $font-size - 2px;
      margin-bottom: 10px;
    }
    h4 {
      margin-bottom: 20px;
    }
    h5, h4 {
      color: $main-black-color;
      font-weight: 600;
    }
    p {
      font-size: $font-size;
      word-spacing: -1px;
    }
  }
}
.efo-map {
  margin: 40px 0px;
  width: 100%;
  height: 400px;
}
a[data-targer='#custom-order']:not(.bnt) {
  font-weight: 600;
  cursor: pointer;
}
.btn {
  &:not([disabled]) {
    cursor: pointer;
  }
  &.btn-big {
    line-height: 32px;
  }
  border-radius: 0;
  font-size: $font-size;
  &.btn-inverse {
    color: #fff;
    background: $second-gray-color;
    filter: none;
    background-color: $second-gray-color;
    border: 1px solid darken( $second-gray-color, 10% );
    border-top: none;
    border-left: none;
  }
  &.btn-inverse:hover, &.btn-inverse:active {
    color: #fff;
    background: lighten( $second-gray-color, 5%);
    filter: none;
    background-color: lighten( $second-gray-color, 5%);
    border: 1px solid lighten(darken( $second-gray-color, 10% ), 5%);
    border-top: none;
    border-left: none;
  }
  &.btn-primary {
    color: #fff;
    background: $btn-primary-color;
    filter: none;
    background-color: $btn-primary-color;
    border: 1px solid darken( $btn-primary-color, 10% );
    border-top: none;
    border-left: none;
  }
  &.btn-primary:hover, &.btn-primary:active {
    color: #fff;
    background: lighten( $btn-primary-color, 5%);
    filter: none;
    background-color: lighten( $btn-primary-color, 5%);
    border: 1px solid lighten(darken( $btn-primary-color, 10% ), 5%);
    border-top: none;
    border-left: none;
  }
  &.btn-success {
    color: #fff;
    background: $main-green-color;
    filter: none;
    background-color: $main-green-color;
    border: 1px solid darken( $main-green-color, 10% );
    border-top: none;
    border-left: none;
  }
  &.btn-success:hover, &.btn-success:active {
    color: #fff;
    background: lighten( $main-green-color, 5%);
    filter: none;
    background-color: lighten( $main-green-color, 5%);
    border: 1px solid lighten(darken( $main-green-color, 10% ), 5%);
    border-top: none;
    border-left: none;
  }
}
.vendor-page {
  &-title {
    color: $main-black-color;
    font-weight: 600;
  }
  &-link {
    margin: 15px 0;
    font-weight: bold;
    i {
      position: absolute;
      display: inline-block;
      margin: -5px 0 0 10px;
      font-weight: 600;
    }
  }
  h3 {
    font-weight: bold;
  }
}
.products-list {
  a.color-black {
    color: $main-blue-color;
  }
  .toggle-link {
    position: absolute;
    display: inline-block;
    width: 15px;
    height: 17px;
    left: 15px;
  }
  &-item {
    ul {
      margin: 5px;
      padding: 0px 5px 0px 10px;
      li {
        &:not(.active) {
          a {
            font-weight: 600;
          }
        }
        &.active {
          a {
            font-weight: 600;
          }
        }
      }
      ul {
        li {
          margin: 0;
          &:not(.active) {
            a {
              font-weight: 400;
            }
          }
          &.active {
            a {
              font-weight: 500;
            }
          }
        }
        padding: 0px 5px 0px 10px;
      }
    }
    li.active {
      &:before {
        color: $main-green-color !important;
      }
      & > a {
        font-weight: 500;
        color: $main-green-color !important;
      }
    }
    &-first {
      display: block;
      list-style-type: none;
      & > li {
        &:before {
          color: $color-gray;
          content: "\f0da";
          font-family: FontAwesome;
          display: inline-block;
          margin-left: -15px;
          width: 15px;
          position: absolute;
        }
        &.open {
          &:before {
            content: "\f0d7"
          }
          & > .products-list-item-second {
            display: block !important;
          }
        }
      }
    }
    &-second {
      & > li {
        &.has-parent {
          position: relative;
          & > .toggle-link {
            left: 0px;
          }
          &:before {
            font-family: FontAwesome;
            font-weight: normal;
            font-size: 13px;
            position: relative;
            padding-left: 2px;
          }
          &:not(.open) {
            &:before {
              content: "\f0da";
            }
          }
          &.open {
            &:before {
              content: "\f0d7";
            }
          }
        }
        &:not(.has-parent):before {
          content: "•";
        }
        &:before {
          color: $color-gray;
          display: inline-block;
          margin-left: -1.5px;
          width: 15px;
          font-size: 23px;
          vertical-align: middle;
        }
        &.open {
          & > .products-list-item-second {
            display: block;
          }
        }
      }
      list-style-type: none;
      display: none;
    }
    h5 {
      a,a:hover,a:active {
        color: $main-green-color;
      }
      font-weight: bold;
      text-transform: uppercase;
    }
    a,a:hover,a:active {
      color: $main-black-color;
    }
    &:nth-child(4n+1) {
      clear: left;
    }
  }
}
.lib-list {
  &-title {
    color: $main-black-color;
  }
  &-item {
    select {
      padding: 5px;
      width: 80%;
    }
    &-title {
      &-page {
        color: $main-blue-color;
        font-size: $font-size + 10px;
        font-weight: 600;
      }
      font-size: $font-size + 2px;
      font-weight: 600;
    }
    &-info {
      margin-top: 10px;
      .label {
        text-transform: uppercase;
        background-color: $color-pink;
      }
    }
  }
}

.art-inline {
  margin-top: 10px;
}
.docs-inline {
  &-item {
    &-title {
      font-size: $font-size - 2px;
    }
  }
}
.big-table{
  &-wrapper {
    &-header {
      overflow-y: hidden;
      overflow-x: hidden;
      width: 100%;
      background-color: #fff;
      border-bottom: 1px solid $main-black-color;
      z-index: 100;
      table {
        tr:not(:first-child) {
          visibility: hidden;
        }
      }

      & + .table-wrapper{
        table {
          tr:first-child {
            visibility: hidden;
          }
        }
      }
    }
    .table-wrapper {
      height: inherit;
      width: 100%;
      overflow: auto;
    }
    background-color: #fff;
    $heights: (

            600: 600,
            700: 700,
            800: 800,
            900: 900,
            1000: 1000
    );

    @each $key, $height in $heights {
      &.big-table-#{$height} {
        max-height: #{$height}px;
      }
    }
    max-width: 100%;
    max-height: 600px;
    overflow: hidden;
  }
}

.pagination.pagination-border>.active>a, .pagination.pagination-border>.active>a:focus, .pagination.pagination-border>.active>a:hover, .pagination.pagination-border>.active>span, .pagination.pagination-border>.active>span:focus, .pagination.pagination-border>.active>span:hover, .pagination.pagination-border>li>a:focus, .pagination.pagination-border>li>a:hover, .pagination.pagination-border>li>span:focus, .pagination.pagination-border>li>span:hover {
  border-color: $main-green-color;
  background-color: transparent;
  color: $main-green-color;
}
.cart {
  position: relative;
  &-label {
    display: none;
    z-index: 1000;
    border-radius: 5px 5px 0 0;
    position: fixed;
    width: 300px;
    height: 54px;
    line-height: 54px;
    background-color: $main-green-color;
    padding: 2px 20px;
    bottom: 0px;
    right: 10%;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    &.animated {
      display: block;
    }
    a {
      cursor: pointer;
      display: block;
      font-weight: 600;
      color: #fff;
    }
  }
}
[data-simplebar=init] .simplebar-content, [data-simplebar=init] .simplebar-scroll-content {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  margin-right: -25px !important;
  margin-bottom: 0px !important;
}
.navbar-dark .navbar-header .navbar-toggle, .navbar-dark .sb-icon-navbar i {
  background-color: $main-green-color;
  background-image: none;
}
@media (max-width: 767px) {
  .navbar-default .navbar-brand {
    font-size: 1.7em;
    word-spacing: -4px;
    padding: 0;
    display: block;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    img {
      margin-top: 5px;
      height: 80%;
      display: inline;
      padding-left: 47px;
    }
  }
}
@media (max-width: 767px) {
  .dropdown.open>a:after {
    top: 10px;
    right: 15px;
    content: "";
  }
  .navbar-default.navbar-dark .navbar-nav>.open>a, .navbar-default.navbar-dark .navbar-nav>.open>a:focus, .navbar-default.navbar-dark .navbar-nav>.open>a:hover {
    background-color: $main-blue-color;
    background-image: none;
    color: #fff;
  }
  .navbar-dark .navbar-collapse {
    border-color: $main-green-color;
  }
}

.checkbox input[type="checkbox"]:focus + label::before,
.checkbox-inline input[type="checkbox"]:focus + label::before {
  border-color: $main-green-color;
}
.checkbox input[type="checkbox"]:checked + label::after,
.checkbox-inline input[type="checkbox"]:checked + label::after {
  content: "";
  background-color: $main-green-color;
  width: 10px;
  height: 10px;
  top: 6px;
  left: 4px;
  transition: all 0.15s ease-in-out;
}
.checkbox, .radio {
  position: relative;
  display: block;
  margin-top: 2px;
  margin-bottom: 2px;
}
.seminarsBox {
  .tab-content {
    border: 1px solid transparent !important;
    background-color: transparent !important;
  }
  .nav-tabs.nav-tabs-ar li {
    width: 50%;
    padding-bottom: 0;
    margin-bottom: 0 !important;
    background-color: transparent !important;
  }
  .nav-tabs.nav-tabs-ar li a {
    border-left: none !important;
    border-right: none !important;
  }
  .nav-tabs.nav-tabs-ar li {
    &.active {
      a {
        color: #fff !important;
        background-color: $main-green-color;
        border-color: $main-green-color !important;
      }
    }
    & {
      a {
        color: $main-green-color !important;
        border-color: $main-green-color !important;
        background-color: transparent;
      }
    }
  }
  .nav-tabs.nav-tabs-ar li a {
    border-bottom: none;
    text-align: center;
    display: block;
    &, &:hover, &:active {
      border: none !important;
      text-transform: uppercase;
      line-height: 30px;
      font-size: $font-size !important;
      font-weight: 600 !important;
      border-color: $main-green-color !important;
      border-bottom: none !important;
    }
  }

  .nav-tabs.nav-tabs-ar {
    border-right: none !important;
    border-left: none !important;
    border-bottom: 3px solid $main-green-color !important;
    border-top: none !important;
    background-color: transparent !important;
  }
}
.alert {
  &-danger {
    color: #fff;
    background-color: lighten( $color-pink, 15%);
    border-color: $color-pink;
  }
  &-success {
    color: #fff;
    background-color: lighten( $main-green-color, 15%);
    border-color: $main-green-color;
  }
  & > .close {
    &, &:hover {
      text-shadow: 0 1px 0 #fff;
      filter: alpha(opacity=30);
      opacity: .3;
      color: #fff;
      opacity: 1;
    }
  }
}
ul.nav {
  width: 100%;
  & > li {
    &.last {
      float: right;
      a {
        line-height: 40px;
        padding: 0;
        i {
          line-height: 40px;
          height: 40px;
          font-size: 22px;
          float: left;
          margin: 0 10px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  ul.nav {
    width: 100%;
    & > li {
      &.last {
        width: 100%;
        float: none;
        text-align: center;
        a {
          line-height: 40px;
          padding: 0;
          i {
            line-height: 40px;
            height: 40px;
            font-size: 22px;
            float: none;
            margin: 0 10px;
          }
        }
      }
    }
  }
}
table.stock {
  tr {
    & > td {
      &:first-child {
      font-weight: 600;
    }
    }
  }
  td:not(.counter) {
    padding: 5px;
    vertical-align: middle;
  }
}
.talbeBox {
  table {
    td {
      &.counter {
        select {
          width: 70px;
          padding: 5px;
        }
      }
      vertical-align: middle;
    }
  }
}
.main {
  .products-list-item ul li.active a, .products-list-item ul li:not(.active) a {
    font-weight: 400 !important;
  }
}
.products-list-item ul a {}
.docs-inline.row {
  & > div[class^=col] {
    &:nth-child(4n + 1) {
      clear: left !important;
    }
  }
}
.vendors-list-main {
  .row {
    & > div[class^=col] {
      &:nth-child(6n + 1) {
        clear: left !important;
      }
    }
  }
}
.products-list-main {
  .row {
    & > div[class^=col] {
      &:nth-child(4n + 1) {
        clear: left !important;
      }
    }
  }
}
.office-list {
  .row {
    & > div[class^=col] {
      &:nth-child(3n + 1) {
        clear: left !important;
      }
    }
  }
}
.radio input[type="radio"]:focus + label::before {
  border-color: $main-green-color;
}
.radio input[type="radio"]:focus + label::before {
  border-color: $main-green-color;
}
.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 9px;
  height: 9px;
  left: 4px;
  top: 4px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: $main-green-color;
  transform: scale(0, 0);
  transition: 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.row {
  & > div[class^=col-sm-6] {
    &:nth-child(2n + 1) {
      clear: left !important;
    }
  }
}

#formSalesSearch {
  .input-group {
    .input-group-addon:first-child {
      cursor: pointer;
      background-color: $main-green-color !important;
      a {
        text-decoration: none;
        border-bottom: 1px solid transparent !important;
        color: #fff !important;
      }
    }
  }
}
.no-border {
  border: none !important;
}
.formSearch .fa-search, .formSearch .glyphicon-search {
  cursor: pointer;
}
.formSearch button.glyphicon-search {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 45px;
  width: 45px;
  cursor: pointer;
}
.shopTableBox {
  table {
    tr.top {
      td {
        vertical-align: top !important;
      }
    }
  }
}

.scroll-top {
  position: fixed;
  bottom: 35px;
  right: 45px;
  color: #fff;
  padding: 10px;
  z-index: 1000;
  background-color: $main-green-color;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    color: #fff;
  }
  i {
    color: #fff;
    font-size: 24px;
  }
}
.questions {
  position: fixed;
  bottom: 10%;
  right: 0;
  z-index: 1000;
  &-dialog {
    background-color: $main-green-color;
    border-radius: 5px 0px 0px 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    opacity: 0.5;
    overflow: hidden;
    white-space: nowrap;
    width: 60px;
    transition: all 0.25s ease-in-out;
    &:hover {
      width: auto;
      opacity: 1;
    }
    a {
      i {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        width: 30px;
        font-size: 30px;
        float: left;
        margin-right: 10px;
      }
      line-height: 30px;
      padding: 20px;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      color: #fff;
      font-weight: bold;
    }
  }
}

.totop {
  z-index: 1000;
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: none;
  a {
    border: 2px solid #fff;
    background-color: $main-green-color;
    color: #fff;
    border-radius: 5px;
    height: 45px;
    line-height: 45px;
    padding: 10px;
    opacity: 0.5;
    font-size: 14px;
    text-transform: lowercase;
    i {
      line-height: 45px;
      font-size: 18px;
      margin-right: 10px;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.pTableWrapper a {
  display: flex;
  flex-direction: column;
  border-bottom: none !important;
  margin: 0px !important;
  padding: 5px !important;
}
.pTableWrapper2 {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}
.pTableWrapper2 a {
  border-bottom: none !important;
  margin-bottom: 3px;
  display: block;
}
.pTableWrapper2 a:before {
  color: #aeaeb0;
  content: "\f0da";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -15px;
  width: 15px;
  position: absolute;
}
.pTableWrapper2 .col-xs-3 > div strong {
  display: block;
  margin: 10px 0;
}
.pTableWrapper a img {
  height: 120px;
  margin-bottom: 20px;
}
.pTableWrapper a strong {
  text-align: center;
}
.pTableWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}