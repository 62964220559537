.h-100 {
  height: 100%;
}
.color-gray {
  color: $color-gray;
}
.color-green {
  color: $main-green-color;
}
.color-black {
  color: $main-black-color;
}
.font-weight-bold {
  font-weight: bold;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.color-pink {
  color: $color-pink;
}
.color-green {
  color: $main-green-color;
}