.notifyjs-metro-base {
	position: relative;
    min-height: 52px;
    color:#444;
}

    .notifyjs-metro-base .image {
        display: table;
        position: absolute;
        height: auto;
        width: auto;
        left: 25px;
        top: 50%;

        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .notifyjs-metro-base .text-wrapper {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        margin: 10px 10px 10px 52px;
        clear: both;
        font-family: 'Segoe UI';
    }

    .notifyjs-metro-base .title {
        font-size: 15px;
        font-weight: bold;
    }

    .notifyjs-metro-base .text {
        font-size: 12px;
        font-weight: normal;
        vertical-align: middle;
    }