.page-content {
  a:not([template-link]) {
    border-bottom: 1px solid $main-blue-color;
    font-weight: 500;
  }
  p {
    margin: 0 0 5px;
    line-height: 1.7rem;
  }
  h1,h2,h3,h4,h5 {
    padding: 15px 0;
  }
  ul {
    li {
      line-height: 1.7rem;
      margin: 0 0 5px;
    }
  }
  .sidebar {
    &-content {
      margin: 20px 0;
    }
    ul {
      li {
        font-weight: 500;
      }
    }
  }
  table {
    border-collapse: separate;
  }
  .fa {
    &-user {
      color: $main-blue-color;
    }
  }
}